import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", width: "800px", height: "600px" },
      model: {
        value: _vm.dialogSync,
        callback: function($$v) {
          _vm.dialogSync = $$v
        },
        expression: "dialogSync"
      }
    },
    [
      _c(
        VCard,
        { staticClass: "px-2 py-2" },
        [
          _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
            _vm._v("Download EOD Report")
          ]),
          _c(
            VLayout,
            { staticClass: "ml-3", attrs: { wrap: "" } },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "H6-Secondary-Left" }, [
                  _vm._v("\n          Units/Cost Centres\n        ")
                ]),
                _c("div", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
                  _vm._v(
                    "\n          Select which reports you would like to download\n        "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "loading-wrapper",
            { attrs: { loading: _vm.loading } },
            [
              _c(
                VList,
                _vm._l(_vm.units, function(unit) {
                  return _c(
                    VListTile,
                    { key: unit.id },
                    [
                      _c(
                        VListTileAction,
                        [
                          _c(VCheckbox, {
                            attrs: { value: unit.id },
                            model: {
                              value: _vm.unitsDownload,
                              callback: function($$v) {
                                _vm.unitsDownload = $$v
                              },
                              expression: "unitsDownload"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VListTileContent,
                        [
                          unit.meta && unit.meta.unit
                            ? _c(VListTileTitle, [
                                _vm._v("Unit " + _vm._s(unit.meta.unit))
                              ])
                            : _c(VListTileTitle, [
                                _vm._v(_vm._s(unit.name))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: {
                    flat: "",
                    color: "blue",
                    disabled: _vm.exportingReports
                  },
                  on: { click: _vm.close }
                },
                [_vm._v("CANCEL")]
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    flat: "",
                    color: "blue",
                    disabled: _vm.exportingReports
                  },
                  on: { click: _vm.downloadReports }
                },
                [_vm._v("DOWNLOAD")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }