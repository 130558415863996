<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <view-title />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs6>
        <v-text-field
          solo
          single-line
          label="Search Sites"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-select
          :items="multigroupsExtra"
          label="Application"
          solo
          single-line
          item-text="name"
          item-value="id"
          :value="currentMultigroup.id"
          @change="goToMultigroup"
        ></v-select>
      </v-flex>
    </v-layout>
    <eod-modal :dialog.sync="EodModal.dialog" :site="EodModal.site" />
    <v-layout wrap>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          class="elevation-1"
          :items="sites"
          :search="search"
          :loading="loading"
        >
          <template v-slot:items="props">
            <tr @click="goToSiteDetails({ id: props.item.id })" style="cursor: pointer;">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.parentMultigroup.name }}</td>
              <td>{{ props.item.address.city | capitalize }}</td>
              <td>{{ props.item.address.country | capitalize }}</td>
              <td>
                <v-btn
                  flat
                  class="Subtitle-1-Primary-Left"
                  style="color: #0d73d8;"
                  @click.stop="downloadReport(props.item)"
                >
                  <v-img
                    :src="require('@/assets/leading-icon.svg')"
                    height="16px"
                    width="16px"
                    style="margin-right: 10px;"
                  />
                  EOD REPORTS
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import EodModal from './site_eod_modal';

export default {
  components: {
    EodModal,
  },

  name: 'report-sites',
  data: () => ({
    sites: [],
    search: '',
    loading: false,
    EodModal: {
      dialog: false,
      site: {},
    },
    headers: [
      {
        text: 'Site Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Platform',
        align: 'left',
        value: 'parentMultigroup.name',
      },
      {
        text: 'City',
        align: 'left',
        value: 'address.city',
      },
      {
        text: 'Country',
        align: 'left',
        value: 'address.country',
      },
      {
        text: '',
        align: 'left',
        value: '',
      },
    ],
  }),

  computed: {
    ...mapState('sites', ['multigroups', 'multigroupMap', 'siteMap']),
    ...mapState('adminPanel', ['active_user_id']),

    multigroupsExtra() {
      return this.multigroups.concat({ id: 'all', name: 'All' });
    },

    currentMultigroup() {
      return this.multigroupsExtra.find((e) => e.id === this.$route.params.multigroup_id);
    },
  },

  watch: {
    currentMultigroup: {
      handler() {
        this.fetchSites();
      },
      immediate: true,
    },
  },

  methods: {
    async fetchSites() {
      this.loading = true;
      if (this.currentMultigroup.id === 'all') {
        await this.$store.dispatch('sites/fetchAll');
        this.sites = Object.values(this.siteMap);
      } else {
        const multigroup = await this.$store.dispatch('sites/fetchMultigroup', {
          id: this.currentMultigroup.id,
        });
        this.sites = multigroup.groups;
      }
      this.loading = false;
    },

    goToMultigroup(id) {
      this.$router.replace({
        name: 'report-sites',
        params: {
          multigroup_id: id,
        },
      });

      if (!localStorage.reportDefaultAppID || localStorage.reportDefaultAppID !== id) {
        localStorage.reportDefaultAppID = id;
      }

      const multigroup = this.multigroupsExtra.find((e) => e.id === id);

      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: `${multigroup.name} Sales Report`,
        to: { name: 'report-sites', params: { multigroup_id: multigroup.id } },
      });
    },

    goToSiteDetails({ id }) {
      this.$router.push({
        name: 'report-site-details',
        params: {
          multigroup_id: this.currentMultigroup.id,
          site_id: id,
        },
      });
    },

    downloadReport(site) {
      this.EodModal.dialog = true;
      this.EodModal.site = site;
    },
  },

  mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Platform',
      to: { name: 'report-multigroup' },
      last: false,
    });
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 1,
      title: `${this.currentMultigroup.name} Sales Report`,
      to: { name: 'report-sites', params: { multigroup_id: this.currentMultigroup.id } },
    });
  },
};
</script>

<style></style>
