<template>
  <v-dialog persistent width="800px" height="600px" v-model="dialogSync">
    <v-card class="px-2 py-2">
      <v-card-title class="H4-Secondary-Center">Download EOD Report</v-card-title>
      <v-layout wrap class="ml-3">
        <v-flex xs12>
          <span class="H6-Secondary-Left">
            Units/Cost Centres
          </span>
          <div class="Body-1-Black-High-Emphasis-Left">
            Select which reports you would like to download
          </div>
        </v-flex>
      </v-layout>
      <loading-wrapper :loading="loading">
        <v-list>
          <v-list-tile v-for="unit of units" :key="unit.id">
            <v-list-tile-action>
              <v-checkbox v-model="unitsDownload" :value="unit.id"></v-checkbox>
            </v-list-tile-action>

            <v-list-tile-content>
              <v-list-tile-title v-if="unit.meta && unit.meta.unit"
                >Unit {{ unit.meta.unit }}</v-list-tile-title
              >
              <v-list-tile-title v-else>{{ unit.name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </loading-wrapper>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="blue" @click="close" :disabled="exportingReports">CANCEL</v-btn>
        <v-btn flat color="blue" @click="downloadReports" :disabled="exportingReports"
          >DOWNLOAD</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { save } from 'save-file';
import { mapActions } from 'vuex';

export default {
  props: {
    dialog: {
      default: false,
    },
    site: Object,
  },

  data: () => ({
    units: [],
    unitsDownload: [],
    loading: true,
    exportingReports: false,
  }),

  computed: {
    dialogSync: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit('update:dialog', v);
      },
    },
  },

  watch: {
    dialog(v) {
      if (v) this.fetchAllLocations();
    },
  },

  methods: {
    ...mapActions('sites', ['getLocation']),
    async fetchAllLocations() {
      try {
        this.loading = true;
        this.units = await Promise.all(
          this.site.locations.map((e) => this.getLocation({ id: e.id })),
        );
        this.unitsDownload = this.units.map((e) => e.id);
      } catch (err) {
        console.error(err);
        this.$toast.error('Could not find unit information');
      }
      this.loading = false;
    },
    async downloadReports() {
      this.exportingReports = true;
      try {
        let queryString = '?format=xlsx';
        if (this.unitsDownload && this.unitsDownload.length > 0) {
          queryString += `&locations=${this.unitsDownload.join(',')}`;
        }

        const { data } = await this.api.get(`/report/eod/group/${this.site.id}${queryString}`);

        this.exportingReports = false;
        await save(data.file, `${this.site.name}-sales-report.${data.format}`);
      } catch (e) {
        console.error(e);
        this.$toast.error('Error exporting report information');
        this.exportingReports = false;
      }
      this.close();
    },
    close() {
      this.units = [];
      this.unitsDownload = [];
      this.dialogSync = false;
    },
  },
};
</script>

<style></style>
