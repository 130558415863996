import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [_c(VFlex, { attrs: { xs12: "" } }, [_c("view-title")], 1)],
        1
      ),
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs6: "" } },
            [
              _c(VTextField, {
                attrs: {
                  solo: "",
                  "single-line": "",
                  label: "Search Sites",
                  "prepend-inner-icon": "mdi-magnify"
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs6: "" } },
            [
              _c(VSelect, {
                attrs: {
                  items: _vm.multigroupsExtra,
                  label: "Application",
                  solo: "",
                  "single-line": "",
                  "item-text": "name",
                  "item-value": "id",
                  value: _vm.currentMultigroup.id
                },
                on: { change: _vm.goToMultigroup }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("eod-modal", {
        attrs: { dialog: _vm.EodModal.dialog, site: _vm.EodModal.site },
        on: {
          "update:dialog": function($event) {
            return _vm.$set(_vm.EodModal, "dialog", $event)
          }
        }
      }),
      _c(
        VLayout,
        { attrs: { wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(VDataTable, {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.sites,
                  search: _vm.search,
                  loading: _vm.loading
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.goToSiteDetails({
                                  id: props.item.id
                                })
                              }
                            }
                          },
                          [
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.parentMultigroup.name))
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(props.item.address.city)
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(
                                    props.item.address.country
                                  )
                                )
                              )
                            ]),
                            _c(
                              "td",
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticClass: "Subtitle-1-Primary-Left",
                                    staticStyle: { color: "#0d73d8" },
                                    attrs: { flat: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.downloadReport(props.item)
                                      }
                                    }
                                  },
                                  [
                                    _c(VImg, {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        src: require("@/assets/leading-icon.svg"),
                                        height: "16px",
                                        width: "16px"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                EOD REPORTS\n              "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }